// config.js
const config = {
    routes: {
      home: '/',
      createGPT: '/create-gpt',
      prompting: '/prompting'
      // Lisää reittejä...
    },
    // Voit lisätä muita yleisiä asetuksia täällä
  };
  
  export default config;