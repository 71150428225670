import React, { useState } from 'react';
import config from '../config';
import Card from './Card'; 
import NavigationButtons from './NavigationButtons';
import Modal from 'react-modal';
import state from '../assets/state.jpg';
import state2 from '../assets/state2.jpg';
import osittaminen from '../assets/osittaminen1.jpg';
import perfect from '../assets/perfect.jpg';
import testaus from '../assets/testaus.jpg';
import opetus from '../assets/opetus.jpg';
import harjoitusteht from '../assets/harjoitusteht.jpg';
import iteratiivinen from '../assets/iteratiivinen.jpg';
import { renderTextWithLineBreaks } from '../utils';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    overflow: 'auto',
    maxHeight: '90vh', // Example fixed max height
    maxWidth: '90vw', // Example fixed max width
  },
};

const Prompting = () => {
  const prevPage = { path: config.routes.createGPT, name: "Edellinen sivu" }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);

  const rules = [
    // Existing rules...
    { id: 1, title: "Selkeät ohjeet", description: "Tarkkojen ja yksityiskohtaisten ohjeiden käyttö vähentää mallin arvailua ja parantaa vastausten tarkkuutta.",  expandedContent: (
      <>
       {renderTextWithLineBreaks("Se ei kuitenkaan tarkoita, että kehotteiden pitäisi olla mahdollisimman lyhyitä. Ennemminkin ohjeistuksella tarkoitetaan \n sitä, että mallille tulisi antaa enemmän kontekstia selkeästi ilmaistuna, kuten alla olevissa esimerkeissä on tehty.")} 
        <ul>
          <li>Esimerkki 1: Kysy ChatGPT:ltä, miten voit parantaa koodisi laatua näin "Katso antamani koodi laadun näkökulmasta kriittisesti ja anna parannusehdotukset"</li>
          <li>Esimerkki 2: Pyydä ChatGPT:tä selittämään, mitä tarkoittaa "state" Reactissa "Selitä kuin olisin aloitteleva ohjelmoija, mitä 'state' tarkoittaa Reactissa"</li>
        </ul>
        <img src={state} alt="State"  />
      </>
    )
  }, { id: 2, title: "Kontekstin lisääminen lähteiden avulla", description: "Lähdetekstien sisällyttäminen kyselyihin parantaa vastausten paikkansapitävyyttä ja tarkkuutta.",  expandedContent: (
    <>
     {renderTextWithLineBreaks("Niiden käytön ansiosta mallille saadaan tuotua enemmän kontekstia. Voit antaa mallille lähteitä tiedostoina tai liittämällä lähdetekstin suoraan osaksi kehotetta, jonka erottaminen omasta kehotteesta tapahtuu esimerkiksi kolmenkertaisten lainausmerkkien avulla.\n")} 
      <ul>
        <li>Esimerkki 1: """Käytä tällaisia kolmenkertaisia lainausmerkkejä erottamaan lähteen omasta kehotteestasi"""</li>
      </ul>
      <img src={state2} alt="State"  />
    </>
  )
},{ id: 3, title: "Osittaminen", description: "Monimutkaisten tehtävien jakaminen pienempiin osiin auttaa mallia pysymään johdonmukaisena ja vähentää virheitä.",  expandedContent: (
  <>
   {renderTextWithLineBreaks("Osittaminen auttaa mallia keskittymään haluamaasi aiheeseen vaadittavalla tarkkuudella. Jos annat liian laajan aihealueen, saat todennäköisesti myös liian laajoja vastauksia.\n Voit tosin aloittaa uuden asian oppimisen kysymällä ensin laajemmin 'Mitä perusasioita minun tulisi tietää React-sovellusten kehittämisestä?'. \nTämä auttaa sinua saamaan yleiskäsityksen Reactin keskeisistä konsepteista, kuten JSX, komponentit, propsit ja tilanhallinta. \nYleiskäsityksen jälkeen voit yksityiskohtaistaa eli osittaa kysymyksiäsi\n Näin lähestyt Reactin oppimista rakenteellisesti, aloittaen suurista linjoista ja siirtyen asteittain yhä yksityiskohtaisempiin aiheisiin.")} 
    <ul>
      <li>Esimerkki 1: "Olen vasta tutustumassa Reactiin ja haluan oppia siitä mahdollisimman paljon. Mitä asioita minun tulee opetella, jotta opin kaiken oleellisen?"</li>
    </ul>
    <img src={osittaminen} alt="State"  />
  </>
)
},{ id: 4, title: "Aika", description: "Ohjeista mallia käyttämään enemmän aikaa tehtävän ratkaisemiseksi.",  expandedContent: (
  <>
   {renderTextWithLineBreaks("Mallille annettaessa kehotetta, on hyvä liittää mukaan ohje käydä läpi tarkempi pohdintaprosessi ennen varsinaisen vastauksen antamista. Tämä auttaa mallia hahmottamaan ongelmaa syvällisemmin ja vähentämään virheitä. Voit esimerkiksi pyytää mallia ensin esittämään oman ratkaisunsa ongelmaan, ennen kuin se vertaa sitä annettuun vastaukseen. Lisäksi voit pyytää mallia arvioimaan, onko se mahdollisesti ohittanut jotakin olennaista tietoa tai aspektia aiemmissa läpikäynneissään. Tämän kaltaiset ohjeet eivät lisää mallin fyysistä laskentatehoa, vaan ohjaavat sitä käyttämään olemassa olevia resurssejaan ja rakennettaan mahdollisimman tehokkaasti. ")} 
    <ul>
      <li>Esimerkki 1: "Ennen kuin annat lopullisen vastauksen, käy läpi seuraavat askeleet ajatuksissasi:

Määrittele ongelma omilla sanoillasi.
Etsi ja listaa kaikki tarvittavat tiedot ratkaisun löytämiseksi.
Laadi yksityiskohtainen suunnitelma siitä, miten aiot ratkaista ongelman, askel askeleelta.
Ratkaise ongelma käyttäen suunnitelmaasi.
Tarkista ratkaisusi ja varmista, että se on johdonmukainen ja looginen.
Kun olet käynyt läpi nämä askeleet, esitä ratkaisusi selkeästi ja perustele, miksi uskot sen olevan oikea."
 </li>
    </ul>
  
  </>
)
},{ id: 5, title: "Ulkoiset työkalut", description: "Ulkoisten työkalujen hyödyntäminen laajentaa mallien tiedonkäsittelykykyä. (mukailtavat GPT:t tulevat korvaamaan pluginit) ",  expandedContent: (
  <>
   {renderTextWithLineBreaks("Voit käyttää ulkoisia työkaluja eli plugineja hyväksi saamaan tarkempia vastauksia ja laajentamaan ChatGPT:n tiedonkäsittelykykyjä. Pluginien avulla voit integroida ChatGPT:n erilaisiin tietokantoihin, API-rajapintoihin ja muihin palveluihin, jotka tarjoavat reaaliaikaista tietoa tai erikoistunutta toiminnallisuutta, jota ei ole suoraan saatavilla itse tekoälymallista. \n  Tässä muutama tapa, joilla pluginit voivat tehostaa ChatGPT:n käyttöä: \n 1. Jotkut pluginit tarjoavat pääsyn erikoistuneisiin toimintoihin, kuten kuvantunnistukseen, kielten kääntämiseen tai monimutkaisten matemaattisten ongelmien ratkaisemiseen. Integroimalla tällaisia palveluita ChatGPT:n kanssa voit hyödyntää tekoälyn tekstinkäsittelykykyjä yhdessä muiden työkalujen tarjoaman erikoistuneen osaamisen kanssa, mikä laajentaa sen sovellusmahdollisuuksia huomattavasti. \n 2. Voit myös käyttää plugineja personoidaksesi käyttäjäkokemusta, esimerkiksi integroimalla ChatGPT:n verkkosivustollesi tai sovellukseesi ja mukauttamalla sen vastauksia palvelemaan käyttäjäsi tarpeita paremmin. Tämä voi sisältää vaikkapa suosituksia perustuen käyttäjän aiempaan toimintaan tai räätälöityjä ohjeita ja vastauksia usein kysyttyihin kysymyksiin.  \n 3. Monet pluginit tarjoavat kehitystyökaluja, jotka auttavat sinua analysoimaan ja parantamaan ChatGPT:n vastauksia. Näitä voivat olla esimerkiksi työkalut, jotka auttavat ymmärtämään, miten eri kehotteet vaikuttavat vastausten laatuun, tai analytiikkatyökalut, jotka tarjoavat tietoa käyttäjien vuorovaikutuksesta ChatGPT:n kanssa, kuten Prompt Perfect")} 
    <ul>
      <li>Esimerkki:  </li>
    </ul>
    <img src={perfect} alt="Pluginit"  />
  </>
)
},{ id: 6, title: "Kommunikointityylien testaus", description: "Testaa erilaisia kommunikointityylejä antamalla rooleja tai pyytämällä vastaus halutulla tyylillä.",  expandedContent: (
  <>
   {renderTextWithLineBreaks("Roolien ja tyylien kokeilu: Aloita määrittelemällä erilaisia rooleja tai persoonallisuuksia, joita haluat testata. Esimerkiksi, voit kokeilla olla tiukka opettaja, utelias oppilas, tai asiantuntija tietyllä alalla. Samoin, kokeile erilaisia kommunikointityylejä, kuten muodollista, epämuodollista, tai kyselymuotoista lähestymistapaa. \n Vuorovaikutuksen testaaminen: Käytä valittuja rooleja ja tyylejä kommunikoidessasi tekoälyn kanssa. Tarkkaile, miten eri lähestymistavat vaikuttavat vastauksen laatuun, relevanssiin ja syvyyteen. \n Muokkaus ja uudelleentestaus: Perustuen havaintoihisi, tee muutoksia lähestymistapaasi ja testaa uudelleen. Tämä voi tarkoittaa roolin tai tyylilajin hienosäätöä tai kokonaan uuden strategian kokeilemista.")} 
    <ul>
      <li>Esimerkki: Hei ChatGPT, voisitko ottaa opettajan roolin ja selittää minulle, mitä 'propsit' ovat Reactissa ja miten niitä käytetään? Kuvittele, että olen juuri aloittanut Reactin opiskelun ja tarvitsen selkeän ja yksinkertaisen selityksen. </li>
    </ul>
    <img src={testaus} alt="Kommunikointityylit"  />
  </>
)
},
{ id: 7, title: "Opiskelun ja ohjelmoinnin tuki", description: "Hyödynnä ChatGPT:tä opiskelun ja ohjelmoinnin tukena henkilökohtaisena opinto-ohjaajana, reaaliaikaisena palautteenantajana, tiedon lähteenä ja koodin selittäjänä, luojana, korjaajana sekä optimoijana. ",  expandedContent: (
  <>
   {renderTextWithLineBreaks("Hyödynnä ChatGPT:tä opiskelun tukena seuraavilla tavoilla: \n 1. Pyydä ChatGPT:tä tiivistämään pitkiä tekstejä tai artikkeleita ja selittämään monimutkaisia konsepteja yksinkertaisesti. \n 2. Käytä ChatGPT:tä tunnistamaan ja korostamaan tärkeimmät ideat ja opetukset mistä tahansa opiskelumateriaalista. \n 3. Hyödynnä ChatGPT:tä henkilökohtaisena opettajana, joka vastaa kysymyksiisi, auttaa opiskelusuunnitelman laatimisessa ja tarjoaa motivointia. \n 4. Pyydä ChatGPT:tä tarjoamaan erilaisia näkökulmia ja lähestymistapoja ongelmiin, mikä voi auttaa syventämään ymmärrystäsi aiheesta. \n 5. Käytä ChatGPT:tä saadaksesi välitöntä palautetta tehtävistäsi, harjoituksista tai kirjoitelmistasi. \n 6. Pyydä ChatGPT:tä luomaan tai ehdottamaan opiskelumateriaaleja, kuten muistiinpanoja, yhteenvetoja tai harjoitustehtäviä. \n 7. Hyödynnä ChatGPT:tä apuna opiskeluprojektien ja aikataulujen suunnittelussa, jotta pysyt järjestäytyneenä ja tehokkaana. \n 8. Hyödynnä ChatGPT:tä oppiaksesi uusia ohjelmointikieliä tai -kirjastoja. Esitä kysymyksiä kielen syntaksista, toiminnallisuuksista tai parhaista tavoista käyttää tiettyjä kirjastoja projekteissasi. \n 9. Käytä ChatGPT:tä koodikatselmointikumppanina. Jaa koodinpätkiä ja pyydä arviointia koodin laadusta, suorituskyvystä tai mahdollisista parannuksista. \n 10. Käytä ChatGPT:tä debuggausapuna, kun kohtaat ohjelmointiongelmia. Kuvaile ongelma yksityiskohtaisesti ja pyydä mahdollisia ratkaisuehdotuksia tai debuggausvinkkejä.  ")} 
    <ul>
      <li>Esimerkki 1: Selitä ja opeta minulle, kuten Reactin aloittajalle, miten filter-funktio toimii Reactissa </li>
    
      <img src={opetus} alt="Opiskelu"  />
      <br></br>
      <br></br>
   
      <li>Esimerkki 2: Luo kolme yksityiskohtaista ja selkeää tehtävänantoa reactin aiheesta: listat ja taulukot</li>
  
    <img src={harjoitusteht} alt="Harjoitus"  />
    </ul>
  </>
)
},{ id: 9, title: "Iteratiivinen kehitys", description: "Promptauksen jatkuva parantaminen ja tarkentaminen syventää ymmärrystä mallin mahdollisuuksista.",  expandedContent: (
  <>
   {renderTextWithLineBreaks("Iteratiivinen kehitys on prosessi, jossa projektia, tuotetta tai palvelua kehitetään pienissä osissa. Tämä mahdollistaa jatkuvan arvioinnin ja parannusten tekemisen jokaisen iteraation jälkeen. Sovellettaessa tätä lähestymistapaa promptaukseen ChatGPT:n kanssa, voit syventää ymmärrystäsi mallin mahdollisuuksista ja parantaa vuorovaikutuksen laatua.")} 
    <ul>
      <li>Esimerkki: </li>
    </ul>
    <img src={iteratiivinen} alt="Iteratiivinen"  />
  </>
)
},{
  id: 10,
  title: "Kriittinen ajattelu ChatGPT:n kanssa",
  description: "Kehitä kriittisen ajattelun taitoja arvioimalla ja haastamalla ChatGPT:n antamia vastauksia.",
  expandedContent: (
    <>
      {renderTextWithLineBreaks("1. Lähteen tarkistus: kun saat vastauksen ChatGPT:ltä, mieti, perustuuko se luotettaviin lähteisiin. Voit pyytää ChatGPT:tä tarjoamaan lähteitä väitteilleen tai tehdä omaa tutkimusta vastauksen vahvistamiseksi. \n\n 2. Argumenttien analysointi: tarkastele ChatGPT:n esittämiä argumentteja kriittisesti. Arvioi, ovatko perustelut johdonmukaisia ja loogisia. Kysy itseltäsi, onko argumentaatiossa aukkoja tai oletuksia, jotka vaativat lisäselvitystä. \n\n 3. Näkökulmat: pyri ymmärtämään eri näkökulmia. Voit haastaa ChatGPT:tä esittämällä vastakkaisia näkemyksiä tai pyytämällä sitä tarkastelemaan aihetta eri näkökulmista. \n\n 4. Syvällisempien kysymysten esittäminen: kehitä taitoasi kysyä syvällisempiä ja tarkempia kysymyksiä vastausten perusteella. Tämä auttaa sinua ymmärtämään aiheita monipuolisemmin ja kehittämään kriittistä ajattelua. \n\n 5. Oman ymmärryksen reflektointi: pohdi, miten vastaukset vaikuttavat omaan ymmärrykseesi aiheesta. Arvioi, onko tietosi lisääntynyt, ja tunnista mahdolliset omat ennakkokäsityksesi, jotka voivat vaikuttaa arviointiisi.")}
    </>
  )
}


    
  ];

  
  const openModal = (rule) => {
    setSelectedRule(rule);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <h2>Tehokas kommunikointi ja ChatGPT:n hyödyntäminen</h2>
      <p>ChatGPT:n tehokas hyödyntäminen ja kommunikointi koostuu useista eri osa-alueista ja ChatGPT:tä voi hyödyntää opiskelun tukena monin tavoin. Tutustu aiheisiin tarkemmin klikkaamalla kuvat auki.</p>
      <div className="rules-container">
        {rules.map((rule) => (
          <Card 
            key={rule.id} 
            title={rule.title} 
            text={rule.description} 
            onClick={() => openModal(rule)}
          />
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Rule Details"
      >
        <h2>{selectedRule?.title}</h2>
        <p>{selectedRule?.description}</p>
        {selectedRule?.expandedContent}
        <button onClick={closeModal}>Sulje</button>
      </Modal>
      <NavigationButtons prevPage={prevPage} />
    </div>
  );
};

export default Prompting;